const UIkit = window.UIkit;

export default function () {
    document.addEventListener("DOMContentLoaded", function () {
        const wrapper = document.querySelector('.product-details .product-short-description-wrapper');
        const div = document.querySelector('.product-details .product-short-description');
        const readMoreShow = document.querySelector('.product-details .product-short-description + .read-more a.show');

        if (div)
        {
            if (div.clientHeight >= 200) {
                readMoreShow.classList.remove('hidden');
            } else {
                wrapper.classList.remove('collapsed');
            }
        }
    });

    UIkit.util.on(document, 'click', '.product-details .product-short-description + .read-more a.show', function (e) {
        const wrapper = document.querySelector('.product-details .product-short-description-wrapper');
        const readMoreShow = document.querySelector('.product-details .product-short-description + .read-more a.show');
        const readMoreHide = document.querySelector('.product-details .product-short-description + .read-more a.hide');

        wrapper.classList.remove('collapsed');
        readMoreShow.classList.add('hidden');
        readMoreHide.classList.remove('hidden');

        e.preventDefault();
    });

    UIkit.util.on(document, 'click', '.product-details .product-short-description + .read-more a.hide', function (e) {
        const wrapper = document.querySelector('.product-details .product-short-description-wrapper');
        const readMoreShow = document.querySelector('.product-details .product-short-description + .read-more a.show');
        const readMoreHide = document.querySelector('.product-details .product-short-description + .read-more a.hide');

        wrapper.classList.add('collapsed');
        readMoreShow.classList.remove('hidden');
        readMoreHide.classList.add('hidden');

        e.preventDefault();
    });
}